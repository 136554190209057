@media screen and (max-width: 1299px) {
  .db_header.mn_hdr .dash_header_links .nav_inn {
    gap: 30px;
  }
  .upld_docs_page .right-side .yl_sqr {
    right: 1%;
  }
}
@media screen and (max-width: 1199px) {
  .db_header.mn_hdr .dash_header_links .nav_inn {
    gap: 22px;
  }

  #basic-navbar-nav .nav-link {
    font-size: 13px;
  }

  .logo-wrap img {
    width: 164.58px;
    height: auto;
    object-fit: cover;
  }
  nav.pre_nav {
    padding: 15px 30px !important;
  }
  .login-page {
    .left-side {
      padding: 40px 30px;
    }
    .right-side {
      height: 630px;
      .tri {
        top: 21px;
      }
      .circle {
        bottom: 20%;
      }
      .rt_bg_img {
        width: 470px;
      }
    }
    // &.sign {
    //   .login_stepsWrap.left-pdng {
    //     padding: 40px 35.5px;
    //   }
    // }
  }
  .pg_heading h1,
  .pg_heading .h1 {
    margin-bottom: 30px;
  }
  .currentPic.logo_inp {
    padding: 4px 1px;
    font-size: 13px;
  }
  .login_stepsWrap.left-pdng {
    padding: 40px 30px;
  }
}

@media screen and (max-width: 1024px) {
  .db_header.mn_hdr .dash_header_links .nav_inn {
    gap: 16px;
  }
  .login-page {
    .right-side {
      height: 600px;
      .right_txt {
        max-width: 450px;
        min-width: 450px;
        width: 450px;
      }
      .rt_bg_img {
        width: 450px;
      }
    }
  }
}

@media (max-width: 991px) {
  .dash_header_links.navbar {
    display: none;
  }

  .db_header .resp_hdr {
    display: block;
  }
  .dbfooterWrap {
    .footerLinks,
    .footerCopyRight {
      .row > .ps-5 {
        padding-left: 12px !important;
      }
    }
    .footerLinks {
      margin-top: 30px !important;
    }
    .disclaimerFooter {
      & > .row > .ps-4.col-md-6 {
        padding-left: 12px !important;
      }
    }
  }
  .resp_hdr.pre_login_hdr {
    display: block;
  }
  .pre_nav {
    padding: 15px 30px !important;
    .nav-right {
      display: none;
    }
  }
  .login-page .left-side {
    padding: 30px 30px;
  }
  .login-page {
    .right-side {
      height: 500px;
      .rt_bg_img {
        width: 350px;
      }

      .right_txt {
        max-width: 350px;
        min-width: 350px;
        width: 350px;
        p {
          font-size: 35px;
        }
      }
    }
    .inputs_wrap.d-flex {
      flex-direction: column;
    }
  }
  .pg_heading h1,
  .pg_heading .h1 {
    margin-bottom: 25px;
    font-size: 35px;
  }
  .login-page.upld_docs_page .right-side {
    .yl_sqr {
      right: 7%;
    }
    .tri {
      top: -12px;
    }
    .big_circle {
      bottom: 0%;
    }
  }
  .login_stepsWrap.left-pdng {
    padding: 30px 30px;
  }
}

@media (max-width: 834px) {
  .login_stepsWrap.left-pdng .onboardIntro h1,
  .login_stepsWrap.left-pdng .onboardIntro .h1 {
    padding-bottom: 40px;
  }
}

@media (max-width: 812px) {
}

@media (max-width: 768px) {
  .profile_mdl_body .administrator_checkbox .res_no_ps.ps-5 {
    padding-left: 12px !important;
  }
  .login-page {
    .log-wrap {
      margin: 20px auto;
      max-width: 550px;
      .left-side {
        border-radius: 0px 0px 20px 20px;
        height: fit-content;
      }
    }
    .rt_col {
      display: none;
    }
    .resp_bg {
      display: block;
    }
    .lft_colm {
      width: 100%;
    }
    &.sign {
      .login_stepsWrap.left-pdng {
        padding: 40px 35.5px;
      }
      .rw_gp_sml {
        row-gap: 20px;
      }
    }
  }
}

@media (max-width: 767px) {
  .footer .footer-txt .ftr-left img.footer-logo {
    height: auto;
    width: 250px;
  }
  .page-wrap {
    padding: 40px 20px 0px 20px !important;
  }
  .db_header.mn_hdr > nav {
    padding: 13px 20px !important;
  }
  .searchHeader {
    background: #000;
    padding: 0px 20px 14px;
  }
  .feedTags_search {
    padding: 40px 20px 0px !important;
  }
  .footer.dbfooterWrap {
    padding: 35px 40px 35px 40px !important;
    .footerLinks > .row > .ps-0,
    .footerCopyRight > .row > .ps-0 {
      padding-left: 12px !important;
    }
  }
  .topSearches_tipsCard > .row > .ps-0 {
    padding-left: 12px !important;
  }
  .feedsMain_wrap .feedsContainer {
    margin-bottom: 25px;
  }

  .modal_wrapper .my-modal,
  .user_login_dtl .my-modal {
    width: 520px;
    max-width: 550px;
  }
  .login-page .resp_bg {
    height: 300px;
  }
  .login_stepsWrap.left-pdng .onboardIntro h1,
  .login_stepsWrap.left-pdng .onboardIntro .h1 {
    padding-bottom: 40px;
  }
  .login-page {
    &.sign {
      .currentPic.logo_inp {
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 646px) {
  .feedTags_search .feedPreviews {
    justify-content: center !important;
  }

  .iconheaders.menu_cstm_drpdn .dropdown-menu.show {
    min-width: 345px;
    .drpMenu_hdr {
      margin-top: 15px;
      padding: 0px 16px;
      margin-bottom: 15px;
    }
  }
  .login_stepsWrap.left-pdng .onboardIntro h1,
  .login_stepsWrap.left-pdng .onboardIntro .h1 {
    padding-bottom: 20px;
  }
}
@media (max-width: 640px) {
  .feedTags_search .tag_select {
    padding: 11px 20px;
  }
  .profile_mdl_hdr_wrap.modal-header {
    padding: 25px 20px 10px;
  }
  .profile_mdl_body.modal-body {
    padding: 10px 20px;
  }
  .profile_mdl_hdr_wrap .modal-title.profile_modal_ttl p {
    font-family: "AirbnbBold" !important;
    font-size: 30px;
  }
  .modal_wrapper .my-modal,
  .user_login_dtl .my-modal {
    width: 470px;
    max-width: 500px;
  }

  .login-page .log-wrap {
    margin: 20px auto;
    max-width: 500px;
  }
  .login-page .left-side {
    padding: 20px 20px;
  }
  .pg_heading h1,
  .pg_heading .h1 {
    margin-bottom: 20px;
    font-size: 30px;
  }
  .login-page .resp_bg {
    height: 250px;
  }
  .login_stepsWrap.left-pdng {
    padding: 20px 20px;
  }
  .onboardStep.payment_head {
    padding-bottom: 20px;
  }
  .login_stepsWrap.left-pdng .companyDetails,
  .login_stepsWrap.left-pdng .officeDetails,
  .login_stepsWrap.left-pdng .adminDetails {
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  .icons-wrap.nav-right {
    margin-top: 16px;
    justify-content: center !important;
    gap: 30px;
  }
  .iconheaders.menu_cstm_drpdn .dropdown-menu.show {
    min-width: 295px;
    transform: translate3d(-200px, 32px, 0px) !important;
  }
  .feedHeader.searchHeader a.dropdown-item {
    font-size: 15px;
    font-family: "Airbnb" !important;
    padding: 7px 16px;
  }
  .modal_wrapper .my-modal,
  .user_login_dtl .my-modal {
    transform: translate(0px, 40px) !important;
    margin: 0 auto;
  }
  .rw_inn_flex {
    margin-bottom: 0px;
  }
  .administrator_checkbox .row > .mb-3 {
    margin-bottom: 0 !important;
    height: fit-content;
    display: flex;
  }
  .profile_mdl_body.modal-body .price_range {
    gap: 10px;
  }
  .modal_wrapper .my-modal,
  .user_login_dtl .my-modal {
    width: 360px;
    max-width: 360px;
  }
  .login-page .log-wrap {
    max-width: 420px;
  }

  .login-page .left-side {
    .forget_password_btn {
      margin-top: 30px;
      margin-bottom: 30px !important;
    }
    .Forget_Password.mt-4 {
      margin-top: 0 !important;
    }
    .form-group.forgot_inp {
      width: 100%;
      .form-control {
        width: 100%;
      }
    }
  }
}

@media (max-width: 480px) {
  .fltrs_prnt.top_fltr .lbl_fltr {
    display: none;
  }
  .login-page .log-wrap {
    max-width: 350px;
    .sign_link_wrp {
      margin-bottom: 0 !important;
    }
    .resp_bg {
      height: 200px;
    }
  }
}

@media (max-width: 414px) {
  .fltrs_prnt.top_fltr button.sortTrigger {
    width: 120px;
  }
  .feedsMain_wrap .rw_hdng {
    font-size: 20px;
  }
  .feedTags_search {
    padding: 40px 10px 0px !important;
  }
  .page-wrap {
    padding: 30px 10px 0px 10px !important;
  }
  .profile_mdl_hdr_wrap .modal-title.profile_modal_ttl p {
    font-size: 25px;
  }
  .modal_wrapper .my-modal,
  .user_login_dtl .my-modal {
    width: 300px;
    max-width: 300px;
  }
  .login-page .left-side p {
    margin-bottom: 8px;
  }
  .login-page .left-side .txt-sm label,
  .login-page .left-side a,
  .login-page .left-side p {
    font-size: 13px !important;
    line-height: 20px;
    text-align: justify;
  }
  .pg_heading h1,
  .pg_heading .h1 {
    margin-bottom: 10px;
    font-size: 20px;
  }
  .login-page .log-wrap {
    max-width: 310px;
  }
  nav.pre_nav {
    padding: 15px 20px !important;
  }
  .mdl_dsn.add_usr_mdl .bg_lbl {
    font-size: 18px;
  }
  .onboardStep.b_border.top_txt p {
    margin-bottom: 15px;
  }
}

@media (max-width: 375px) {
  .profile_mdl_hdr_wrap.modal-header {
    padding: 15px 20px 10px;
  }
  .profile_mdl_hdr_wrap .modal-title.profile_modal_ttl p {
    font-size: 20px;
  }
  .profile_mdl_body.modal-body {
    .row .mb-4.form-group {
      margin-bottom: 10px !important;
    }
    padding: 10px 20px;
    .rw_inn_flex {
      gap: 0px;
    }
    .administrator_checkbox {
      margin-top: 15px;
    }
  }
  .profile_mdl_ftr.border-0.mb-4.modal-footer {
    margin-bottom: 0px !important;
  }
  .mdl_dsn.add_usr_mdl .bg_lbl {
    font-size: 15px;
  }
}

@media (max-width: 360px) {
  .fltrs_prnt.top_fltr button.sortTrigger {
    width: 100px;
  }
  .feedsContainer .feedContent_header a {
    font-size: 14px;
  }
  .iconheaders.menu_cstm_drpdn .dropdown-menu.show {
    min-width: 275px;
  }
  .iconheaders .menu_itm_wrp img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    margin-right: 8px;
  }
  .feedHeader.searchHeader a.dropdown-item {
    font-size: 14px;
    font-family: "Airbnb" !important;
    padding: 7px 16px;
  }
  .login-page .log-wrap {
    max-width: 280px;
  }
  .login-page .left-side .forget_password_btn {
    margin-top: 20px;
    margin-bottom: 20px !important;
  }
}

@media (max-width: 320px) {
}
